import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "../../common/state/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import {
  getEditedProjectGroup,
  getNewProjectGroup,
  getSelectedProjectGroup,
  setEditedMenuMedia,
  setNewProjectGroup,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { DynamicMedia } from "../../features/graph/components/DynamicField";
import {
  mediaSelector,
  setMediaFilter,
  setOriginalMediaGallery,
} from "../../features/gallery/state/gallerySlice";
import { useEffect, useState } from "react";
import { IMedia } from "../../model/unityObject";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import ModalGallery from "../../features/graph/components/ModalGallery";
import { FooterButtons } from "../../common/components/FooterButtons";

interface ICreateProjectGroupMenuMedia {
  incrementStep?(): void;
  decrementStep(): void;
  editMode?: boolean;
}

const MAX_MEDIA_SIZE_IN_BYTE = 30 * 1024 * 1024; // => 30 Mo

const CreateProjectGroupMenuMedia = ({
  incrementStep,
  decrementStep,
  editMode = false,
}: ICreateProjectGroupMenuMedia) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { register, handleSubmit, setValue, control } = useForm<any>();

  const mediasList = useSelector(mediaSelector);
  const currentFilter = useSelector((state: RootState) => state.media.currentFilter);

  const [currentMenuMediaId, setCurrentMenuMediaId] = useState<number>(5346);

  const { newProjectGroup, selectedProjectGroup, editedProjectGroup } = useSelector(
    (state: RootState) => {
      return {
        newProjectGroup: getNewProjectGroup(state),
        selectedProjectGroup: getSelectedProjectGroup(state),
        editedProjectGroup: getEditedProjectGroup(state),
      };
    },
  );

  useEffect(() => {
    dispatch(closeStandardModal("modal-gallery"));
  }, []);

  // Dance around the media preview issues that arose from repurposing the "EditNodeForm" component
  const getEnhancedBgMusicField = () => {
    const enhancedField = {
      type: "media",
      mediaType: "image",
      fieldName: "source_app_main_menu_media",
      currentValue: currentMenuMediaId,
      relevantSourceMedia: mediasList.find(
        (m: IMedia) => Number(m.id) === Number(currentMenuMediaId),
      ),
    };
    if (!currentMenuMediaId) {
      enhancedField.currentValue = -1;
      enhancedField.relevantSourceMedia = mediasList.find(
        (m: IMedia) => Number(m.id) === Number(currentMenuMediaId),
      );
    }
    return enhancedField;
  };

  const openGallery = () => {
    dispatch(setMediaFilter({ ...currentFilter, mediaType: "image" }));
    dispatch(setOriginalMediaGallery(currentMenuMediaId));
    dispatch(openStandardModal("modal-gallery"));
  };

  const onSubmit = () => {
    openGallery();
  };

  // Callback from ModalGallery selection
  const onMediaChange = (selectedMedia: IMedia) => {
    dispatch(closeStandardModal("modal-gallery"));

    setCurrentMenuMediaId(Number(selectedMedia.id));
  };

  useEffect(() => {
    if (editMode) {
      const tbnId = Number(editedProjectGroup.source_app_main_menu_media.id);
      setValue("menuMediaId", tbnId);
      setCurrentMenuMediaId(tbnId);
    } else {
      const tbnId = newProjectGroup.menuMediaId;
      setValue("menuMediaId", tbnId);
      setCurrentMenuMediaId(tbnId);
    }
  }, [editedProjectGroup.source_app_main_menu_media]);

  const enhancedField = getEnhancedBgMusicField();

  return (
    <>
      <h1 className="text-2xl my-5 px-6 font-medium">{t("general.selectMenuMedia")}</h1>
      <ModalGallery nodeId={"new-project-group-tbn"} onMediaChange={onMediaChange} />

      <form className="flex flex-col grow w-full justify-between" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-10 w-full my-5 px-6">
          <div className="flex flex-col items-center justify-center bg-green-200 p-6 w-full max-w-max">
            <Controller
              control={control}
              name={"source_app_main_menu_media"}
              render={({ field }) => (
                <DynamicMedia
                  field={field as any}
                  fieldType={enhancedField.type}
                  register={register}
                  handleButtonClick={() => {
                    openGallery();
                  }}
                  currentMedia={enhancedField.currentValue as number}
                  media={enhancedField.relevantSourceMedia as IMedia}
                />
              )}
            />
          </div>
          {enhancedField?.relevantSourceMedia?.size_in_bytes > MAX_MEDIA_SIZE_IN_BYTE && (
            <p className="text-red-500">{t("pages.createProjectGroup.menuMediaTooBig")}</p>
          )}
        </div>

        <FooterButtons
          cancelText={t("general.cancel")}
          cancelCb={() =>
            navigate(`/project-groups${editMode ? `/${selectedProjectGroup.id}/list` : ""}`)
          }
          backText={t("general.back")}
          backCb={decrementStep}
          nextText={t("general.next")}
          nextCb={() => {
            if (editMode) {
              dispatch(setEditedMenuMedia(enhancedField.relevantSourceMedia as IMedia));
            } else {
              dispatch(setNewProjectGroup({ ...newProjectGroup, menuMediaId: currentMenuMediaId }));
            }

            if (incrementStep) {
              incrementStep();
            }
          }}
          nextDisabled={
            !enhancedField.relevantSourceMedia ||
            enhancedField?.relevantSourceMedia?.size_in_bytes > MAX_MEDIA_SIZE_IN_BYTE
          }
        />
      </form>
    </>
  );
};

export default CreateProjectGroupMenuMedia;
