import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import Navigation from "../../common/components/Navigation/Navigation";
import StepperMUI from "../../common/components/Stepper/Stepper";
import { getSelectedProjectGroup } from "../../features/projectGroups/state/projectGroupsSlice";
import CreateProjectGroupName from "./CreateProjectGroupName";
import CreateProjectGroupThumbnail from "./CreateProjectGroupThumbnail";
import CreateProjectGroupTheme from "./CreateProjectGroupTheme";
import CreateProjectGroupMenuMedia from "./CreateProjectGroupMenuMedia";

export default function EditProjectGroupNameWrapper() {
  const selectedProjectGroup = useSelector(getSelectedProjectGroup);

  const [step, setStep] = useState(0);
  const incrementStep = () => setStep(step + 1);
  const decrementStep = () => setStep(step - 1);

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
      default:
        return <CreateProjectGroupName incrementStep={incrementStep} editMode />;
      case 1:
        return (
          <CreateProjectGroupThumbnail
            incrementStep={incrementStep}
            decrementStep={decrementStep}
            editMode
          />
        );
      case 2:
        return (
          <CreateProjectGroupMenuMedia
            incrementStep={incrementStep}
            decrementStep={decrementStep}
            editMode
          />
        );
      case 3:
        return (
          <CreateProjectGroupTheme
            incrementStep={incrementStep}
            decrementStep={decrementStep}
            editMode
          />
        );
    }
  };
  return (
    <>
      <Navigation
        title={`${t("pages.editProjectGroupName.title")}: ${selectedProjectGroup.name}`}
      />
      <StepperMUI step={step} mode="editProjectGroup" />
      {renderStep(step)}
    </>
  );
}
