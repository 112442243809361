import { useMemo, useEffect, useState, useRef } from "react";
import { Dropdown } from "../../../common/components/Input";
import { useForm, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../common/state/store";
import { SearchMediaTitle } from "./SearchForFilterGallery";

import {
  currentFilterSelector,
  IMediaFilter,
  IMediaState,
  mediaConfigSelector,
  setMediaFilter,
  getOriginalMediaGalleryId,
  mediaSelector,
  resetMediaFilters,
  getCurrentModeListGallery,
} from "../state/gallerySlice";
import { getNodeById } from "../../graph/state/graphLegacy";
import { getCurrentProject } from "../../projects/state/projectsSlice";
import { useTranslation } from "react-i18next";
import { IMedia } from "../../../model/unityObject";
import { getCurrentUser } from "../../profile/state/profileSlice";
import SortableDropdown from "src/common/components/Input/SortableDropdown";

export const MediaSearchPanelContainer = ({
  nodeId,
  allowedMediaTypeOverride,
  mediaForSearchTitle,
}: {
  nodeId: string;
  allowedMediaTypeOverride?: string;
  mediaForSearchTitle: IMedia;
}) => {
  const { register, watch, setValue, reset, getValues } = useForm<IMediaFilter>({
    defaultValues: {
      order: "date_desc",
      mediaType: "all",
      labels: [],
      media360tag: "",
      filename: "",
      property: "all",
    },
  });
  const dispatch = useDispatch();
  const [showMedia360TagFilter, setShowMedia360TagFilter] = useState<boolean>(false);
  const { config, currentFilter, originalMediaId } = useSelector((state: RootState) => {
    return {
      config: mediaConfigSelector(state),
      currentFilter: currentFilterSelector(state),
      originalMediaId: getOriginalMediaGalleryId(state),
    };
  });

  const watchFields = watch();
  const currentNode =
    Number(nodeId) !== 0 ? useSelector((state: RootState) => getNodeById(state, nodeId)) : null;
  useEffect(() => {
    if (JSON.stringify(currentFilter) !== JSON.stringify(watchFields)) {
      dispatch(setMediaFilter(watchFields));
    }
  }, [watchFields]);

  useEffect(() => {
    dispatch(resetMediaFilters({ cb: reset }));
  }, []);

  useEffect(() => {
    if (
      getValues()?.media360tag === "video180stereo" &&
      currentFilter.mediaType !== "video" &&
      currentFilter.mediaType !== "all"
    ) {
      setValue("media360tag", "");
    }

    if (currentFilter.mediaType === "audio" || currentFilter.mediaType === "pdf") {
      setValue("media360tag", "");
      setShowMedia360TagFilter(false);
    } else if (
      currentFilter.mediaType === "model3d" &&
      currentNode?.modelName === "content-scene"
    ) {
      setShowMedia360TagFilter(false);
    } else {
      setShowMedia360TagFilter(true);
    }
  }, [currentFilter?.mediaType]);

  return (
    <MediaSearchPanel
      nodeId={nodeId}
      mediaTypeFromGallery={currentFilter.mediaType}
      config={config}
      register={register}
      setValue={setValue}
      allowedMediaTypeOverride={allowedMediaTypeOverride}
      showMedia360TagFilter={showMedia360TagFilter}
      originalMediaId={originalMediaId}
      mediaForSearchTitle={mediaForSearchTitle}
    />
  );
};

const MediaSearchPanel = ({
  register,
  config,
  setValue,
  mediaTypeFromGallery,
  nodeId,
  allowedMediaTypeOverride,
  showMedia360TagFilter,
  originalMediaId,
  mediaForSearchTitle,
}: {
  mediaTypeFromGallery: string;
  config: IMediaState["config"];
  register: UseFormRegister<IMediaFilter>;
  setValue: UseFormSetValue<IMediaFilter>;
  nodeId: string;
  allowedMediaTypeOverride?: string;
  showMedia360TagFilter: boolean;
  originalMediaId: number;
  mediaForSearchTitle: IMedia;
}) => {
  const { t } = useTranslation();

  const searchMediaTitleRef = useRef(null);
  const currentProject = useSelector(getCurrentProject);
  const nodeToEdit = useSelector((state: RootState) => getNodeById(state, nodeId));
  const allMedias = useSelector(mediaSelector);
  const getCurrentuser = useSelector(getCurrentUser) as any;
  const currentModeListGallery = useSelector(getCurrentModeListGallery);
  const marginForMediaFormat = getCurrentuser?.preferred_language.id === "1" ? "mr-2" : "mr-3";

  useEffect(() => {
    mediaTypeFromGallery && setValue("mediaType", mediaTypeFromGallery);
    const projectId = currentProject.id;

    if (
      (nodeToEdit?.modelName === "content-scene" || nodeId === `newscene-${Number(projectId)}`) &&
      mediaTypeFromGallery === "model3d"
    ) {
      setValue("media360tag", "media360");
    } else {
      setValue("media360tag", "");
    }
  }, []);

  const [mediaTypes, setMediaType] = useState<string[]>([]);

  useEffect(() => {
    if (nodeId !== "0") {
      if (allowedMediaTypeOverride && allowedMediaTypeOverride !== "") {
        setMediaType(allowedMediaTypeOverride.split("|").map((v) => v.trim()));
      } else {
        if (
          nodeToEdit?.modelName === "content-scene" ||
          nodeId === `newscene-${currentProject.id}`
        ) {
          if (mediaTypeFromGallery !== "model3d") {
            setMediaType(["video", "image"]);
            const m = allMedias.find((v) => Number(v.id) === Number(originalMediaId));
            if (m?.type) {
              setValue("mediaType", m?.type);
            }
          } else {
            setMediaType([mediaTypeFromGallery]);
          }
        } else {
          setMediaType([mediaTypeFromGallery]);
        }
      }
    } else {
      setMediaType(["video", "image", "model3d", "pdf", "audio", "all"]);
      setValue("mediaType", "all");
    }
  }, [nodeId]);

  const dropdownOptions = useMemo(() => {
    const disabledTooltipText = t(
      `pages.gallery.filters.mediaTypeUnavailableFor${
        nodeToEdit?.modelName === "content-scene" ? "Scene" : "Interaction"
      }`,
    );

    const arr = [
      {
        value: "all",
        optionText: t("general.all"),
        disabled: !mediaTypes.includes("all"),
        disabledTooltipText,
      },
    ];

    return arr.concat(
      config?.mediaType?.map((type: string) => {
        return {
          value: type,
          optionText: t(`pages.gallery.mediaType.${type}`),
          disabled: !mediaTypes.includes(type),
          disabledTooltipText,
        };
      }),
    );
  }, [mediaTypes]);

  const get360TagFilters = (mediaType: string) => {
    const filters =
      mediaType === "model3d"
        ? [
            { value: "", optionText: t("general.all") },
            {
              value: "media360",
              optionText: t("pages.gallery.filters.environments3d"),
            },
            { value: "others", optionText: t("pages.gallery.filters.objects3d") },
          ]
        : [
            { value: "", optionText: t("general.all") },
            { value: "media360", optionText: t("pages.gallery.filters.medias360") },
            { value: "media2d", optionText: t("pages.gallery.filters.medias2D") },
          ];

    if (mediaType === "video" || mediaType === "all") {
      filters.push({
        value: "video180stereo",
        optionText: t("pages.gallery.filters.video180"),
      });
    }

    return filters;
  };

  const additionalOptions = [
    { value: "size_desc", optionText: t("general.size_desc") },
    { value: "size_asc", optionText: t("general.size_asc") },
  ];

  return (
    <div className="sticky z-10 flex flex-col mb-9">
      <div id="gallery-filter-container" className="flex items-center">
        <div className="mr-2" ref={searchMediaTitleRef}>
          <SearchMediaTitle mediaForSearchTitle={mediaForSearchTitle} />
        </div>
        <div className="mr-2 format-dropdown-in-gallery">
          <p className="text-gray-500 font-medium mb-2">{t("pages.gallery.filters.format")}</p>
          <Dropdown
            borderOff
            width={"lg:w-24 md:w-20 1735px:w-36 1735px:mr-2 "}
            options={dropdownOptions}
            registerFct={() => register("mediaType")}
            onChangeCb={(value: any) => setValue("mediaType", value)}
            asDefaultValue={true}
            label=""
          />
        </div>
        {showMedia360TagFilter && (
          <div className={`${marginForMediaFormat}`}>
            <p className="text-gray-500 font-medium mb-2 ">
              {t("pages.gallery.filters.mediatype")}
            </p>
            <div className="space-y-4">
              <Dropdown
                borderOff
                width={"w-[7.5rem] 1735px:w-48 1735px:mr-2"}
                options={get360TagFilters(mediaTypeFromGallery)}
                label=""
                registerFct={() => register("media360tag")}
                onChangeCb={(value: any) => setValue("media360tag", value)}
                asDefaultValue={true}
              />
            </div>
          </div>
        )}
        <div className="mr-2">
          <p className="text-gray-500 font-medium mb-2">{t("general.property")}</p>
          <div className="space-y-4">
            <Dropdown
              borderOff
              width={"md:w-20 lg:w-24 2xl:w-72 1735px:mr-2"}
              options={[
                { value: "all", optionText: t("general.all") },
                { value: "public", optionText: t("general.media_Public") },
                { value: "private", optionText: t("general.media_Private") },
              ]}
              label=""
              registerFct={() => register("property")}
              onChangeCb={(value: any) => setValue("property", value)}
              asDefaultValue={true}
            />
          </div>
        </div>
        {!currentModeListGallery && (
          <div className="mr-2">
            <p className="text-gray-500 font-medium mb-2">{t("pages.gallery.filters.orderBy")}</p>
            <div className="space-y-4">
              <SortableDropdown
                register={register}
                setValue={setValue}
                defaultValue="date_desc"
                additionalOptions={additionalOptions}
                onChangeCb={(value) => setValue("order", value)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
